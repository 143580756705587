import { FC, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Lottie from 'lottie-react';

import { useRouter } from 'next/router';
import { Button } from '@agendapro/emerald/v2';
import { useReservation } from '@/context/ReservationsContext';
import { ServicesDateSelector } from './ServicesDateSelector/ServicesDateSelector';
import ClientForm from './ClientForm/ClientForm';
import { ReservationsSummary } from './ReservationsSummary';
import { ServiceProviderSelector } from './ServiceProviderSelector';
import { useLockBodyScroll, usePageContext } from '@/hooks';
import { Drawer } from '@/UI';
import { PostPay } from '../PostPay';

import { ModalPlans } from './ModalPlans';
import { useDoReservation } from '@/hooks/useDoReservation';
import { ModeSelection } from './ModeSelection';
import { Step } from '@/context/ReservationsContext/ReservationContext.types';
import { devices } from '@/UI/Styles/Queries';
import { ModalUnavailableBookingDate } from './ModalUnavailableBookingDate';
import { getServicesTotal } from '@/utils';
import { StepDialog } from '@/UI/Organisms/StepDialog';
import ServiceList from './ServiceList/ServiceList';
import LoadingAnimation from './BookingLoader/data';
import * as St from './ReservationModal.styles';
import { handleNextStepDesktop, handleNextStepMobile } from './Helpers';
import PoweredBy from '../PoweredBy/PoweredBy';

const ReservationModal: FC = () => {
  const { reservations, dispatch } = useReservation();
  const {
    actionType,
    bookingServices,
    canBePaidOnline,
    checkDisabled,
    handleActionTaken,
    handleModalPlans,
    hideModalPlans,
    isBookingLoading,
    bookMutationLoading,
    isModalPlansShown,
    mustBePaidOnline,
    noneIncluded,
    isUnavailableBookingModalShown,
  } = useDoReservation();
  const { isSalfa } = usePageContext();
  const { t } = useTranslation();
  const {
    bookingMode,
    canChooseProvider,
    companyBookingInfo,
    currentIndex,
    handleClose,
    handleCloseNow,
    hourSelected,
    isEditing,
    isSession,
    providerPreSelected,
    serviceBeingReserved,
    servicesToReserve,
    servicesWithProviders,
    step,
  } = reservations;
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });
  const router = useRouter();
  const [redirecting, setIsRedirecting] = useState(false);

  const [firstStep, setFirstStep] = useState<Step | ''>('');
  const [showReservationSummary, setShowReservationSummary] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { creative_source: creativeSource } = router.query as {
    // eslint-disable-next-line camelcase
    creative_source?: string;
  };

  useEffect(() => {
    const receivedStep = step;

    setFirstStep(receivedStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  router.events.on('routeChangeStart', () => setIsRedirecting(true));
  const setStep = (step: Step) => dispatch({ type: 'SET_STEP', payload: step });
  const marketBack = !isXLarge && creativeSource === 'marketplace' && step === 'dateSelection';

  const handleMarketBack = () => window.history.back();

  const handlePrevious = () => {
    if (step === 'serviceSelection') {
      if (isSession) {
        return setStep('dateSelection');
      }
      if (!isXLarge && serviceBeingReserved?.bundled) {
        return setStep('dateSelection');
      }
      return setStep('modeSelection');
    }
    if (step === 'dateSelection') {
      if (!isXLarge) {
        if (isEditing) {
          if (isSession && canChooseProvider !== 'CANT') {
            return setStep('providerSelection');
          }
          return undefined;
        }
        if (
          canChooseProvider !== 'CANT' &&
          (bookingMode === 'consecutive' || isSession || serviceBeingReserved?.bundled)
        ) {
          return setStep('providerSelection');
        }
        if (canChooseProvider === 'CANT' && bookingMode === 'consecutive') {
          return setStep('modeSelection');
        }
        if (servicesToReserve.length > 1) {
          return setStep('serviceSelection');
        }
      }
      if (bookingMode === 'consecutive' || serviceBeingReserved?.bundled || isSession) {
        if (servicesToReserve[currentIndex].serviceProviders.length === 1) {
          return setStep('modeSelection');
        }
        return setStep('providerSelection');
      }
      if (bookingMode === 'separate') {
        return setStep('modeSelection');
      }
      if (canChooseProvider !== 'CANT' && (bookingMode === 'consecutive' || isSession)) {
        return setStep('providerSelection');
      }
    }
    if (step === 'providerSelection') {
      if (!isXLarge) {
        if (isEditing) {
          if (!isSession) {
            return setStep('dateSelection');
          }
          return undefined;
        }
        if (bookingMode === 'consecutive') {
          return setStep('modeSelection');
        }
        return setStep('dateSelection');
      }
      if (isSession && handleClose) {
        return handleClose();
      }
      if (bookingMode === 'consecutive') {
        return setStep('modeSelection');
      }
      if (bookingMode === 'separate') {
        return setStep('dateSelection');
      }
      return setStep('dateSelection');
    }
    if (step === 'clientForm' || (step === 'reservationSummary' && companyBookingInfo.clientExclusive)) {
      if (!isXLarge) {
        if (isSession || (bookingMode === 'separate' && !isSalfa)) {
          return setStep('serviceSelection');
        }
        if (
          (bookingMode === 'consecutive' && !isSalfa) ||
          (servicesToReserve.length === 1 &&
            (servicesToReserve[0].bundled || canChooseProvider === 'CANT' || providerPreSelected)) ||
          isSalfa
        ) {
          return setStep('dateSelection');
        }
        return setStep('providerSelection');
      }
      if (isSalfa || bookingMode === 'consecutive' || canChooseProvider === 'CANT' || providerPreSelected) {
        return setStep('dateSelection');
      }

      return setStep('providerSelection');
    }
    if (step === 'reservationSummary') {
      return setStep('clientForm');
    }
    return null;
  };

  const handlePreviousDisabled = () => {
    if (step === 'dateSelection') {
      if (
        isSalfa ||
        ((canChooseProvider === 'CANT' || (providerPreSelected && servicesToReserve.length === 1 && !isSession)) &&
          (isSession || (bookingMode === 'consecutive' && isXLarge))) ||
        (servicesToReserve.length === 1 && !isSession && !serviceBeingReserved?.bundle)
      ) {
        return true;
      }
    }
    if (!serviceBeingReserved && step !== 'modeSelection' && step !== 'serviceSelection' && !isXLarge) {
      return true;
    }
    return false;
  };

  const handleNext = () => {
    if (skipClientForm) {
      setShowReservationSummary(true);
      if (!isXLarge) {
        handleNextStepMobile({ reservations, isSalfa, dispatch });
      }
      return;
    }
    if (ref && ref.current) {
      ref.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
    const nextStep = handleNextStepDesktop({
      reservations,
      runDispatch: true,
      isSalfa,
      dispatch,
    });

    if (nextStep) {
      setStep(nextStep);
    }
  };

  const handleNextDisabled = () => {
    if (step === 'modeSelection' && !bookingMode) {
      return true;
    }

    if (
      serviceBeingReserved?.bundled &&
      step === 'providerSelection' &&
      !(Object.entries(servicesWithProviders).length === serviceBeingReserved.bundle?.length)
    ) {
      return true;
    }
    if (step === 'providerSelection') {
      if (
        bookingMode === 'consecutive' &&
        !(Object.entries(servicesWithProviders).length === servicesToReserve.length)
      ) {
        return true;
      }
    }
    if (step === 'dateSelection' && !hourSelected) {
      return true;
    }
    if (
      step === 'providerSelection' &&
      bookingMode !== 'consecutive' &&
      !serviceBeingReserved?.bundled &&
      !servicesToReserve[currentIndex]?.serviceProvider
    ) {
      return true;
    }
    return false;
  };

  const showReserveButton = () => {
    const total = getServicesTotal(servicesToReserve[0]?.bundled ? servicesToReserve[0].bundle : servicesToReserve);

    return !mustBePaidOnline || !canBePaidOnline || total === 0;
  };

  const showPayAndReserveButton = () => {
    const total = getServicesTotal(servicesToReserve[0]?.bundled ? servicesToReserve[0].bundle : servicesToReserve);

    if (canBePaidOnline && total > 0) {
      return true;
    }
    return false;
  };

  const getNextButtonId = () => {
    if (step === 'providerSelection') {
      return 'button_confirm_provider';
    }
    if (step === 'dateSelection') {
      return 'button_date_selection';
    }
    return '';
  };
  const getMainDialog = () => {
    if (step === 'modeSelection') {
      return <ModeSelection />;
    }
    if (step === 'dateSelection') {
      return <ServicesDateSelector handleNext={handleNext} />;
    }
    if (step === 'serviceSelection') {
      return <ServiceList />;
    }
    if (step === 'providerSelection') {
      return <ServiceProviderSelector handleNext={handleNext} />;
    }
    if (step === 'clientForm') {
      return <ClientForm handleClose={() => dispatch({ type: 'SET_STEP', payload: 'reservationSummary' })} />;
    }
    if (step === 'reservationSummary' && (isXLarge || (!isXLarge && companyBookingInfo.clientExclusive))) {
      return <ReservationsSummary />;
    }
    if (step === 'iframePostPay') {
      return <PostPay />;
    }
    return undefined;
  };

  const skipClientForm =
    companyBookingInfo.clientExclusive &&
    handleNextStepDesktop({
      runDispatch: false,
      reservations,
      isSalfa,
      dispatch,
    }) === 'clientForm';

  const getSideDialog = () => {
    if (step === 'clientForm' || showReservationSummary) {
      return <ReservationsSummary />;
    }
    if (['dateSelection', 'providerSelection', 'serviceSelection'].includes(step)) {
      return <ServiceList />;
    }
    if (step === 'modeSelection' || skipClientForm) {
      return <ServiceList />;
    }
    return undefined;
  };

  const onClickReserve = () => {
    handleActionTaken('reserve');
  };

  const onClickPayAndReserve = () => {
    handleActionTaken('payAndReserve');
  };

  useLockBodyScroll();
  return (
    <StepDialog
      sideDialog={getSideDialog()}
      mainDialog={getMainDialog()}
      handleClose={handleClose!}
      handleCloseNow={handleCloseNow!}
      shouldCloseNow={!servicesToReserve[currentIndex]?.hourSelected}
      handlePrevious={marketBack ? handleMarketBack : handlePrevious}
      previousDisabled={handlePreviousDisabled()}
      ref={ref}
      marketBack={marketBack}
    >
      {isModalPlansShown && (
        <ModalPlans
          actionType={actionType}
          showModal={isModalPlansShown}
          handleClose={hideModalPlans}
          handleConfirm={handleModalPlans}
          hideModalPlans={hideModalPlans}
          bookingServices={bookingServices}
          currentIndex={currentIndex}
          noneIncluded={noneIncluded}
        />
      )}
      {isUnavailableBookingModalShown && (
        <ModalUnavailableBookingDate
          bookingServices={bookingServices?.bookingsData}
          showModal={isUnavailableBookingModalShown}
        />
      )}
      {isXLarge && !['payment', 'iframePostPay'].includes(step) && (
        <Drawer className="fixed z-[5] bottom-0">
          <div className="flex flex-col items-center">
            <div className={`w-[1100px] flex ${firstStep === step ? 'justify-end' : 'justify-between'}`}>
              {firstStep !== step && (
                <St.ButtonContainer>
                  <Button
                    onClick={handlePrevious}
                    disabled={handlePreviousDisabled()}
                    data-testid="button_previous"
                    className="btnOutline"
                  >
                    {`${t('BUTTON_PREVIOUS')}`}
                  </Button>
                </St.ButtonContainer>
              )}
              <St.ButtonContainer>
                {['clientForm'].includes(step) || showReservationSummary ? (
                  <St.ReservationButtonsWrap centered={step === 'reservationSummary'}>
                    {showPayAndReserveButton() && (
                      <Button
                        id="button_pay_and_book"
                        disabled={handleNextDisabled() || checkDisabled()}
                        onClick={onClickPayAndReserve}
                        data-testid="button_pay_and_reserve"
                        data-cy="button_pay_and_reserve"
                      >
                        {`${t('BUTTON_PAY_AND_RESERVE')}`}
                      </Button>
                    )}
                    {showReserveButton() && (
                      <Button
                        id="button_just_book"
                        className={
                          !mustBePaidOnline && showPayAndReserveButton() ? 'btnOutline' : 'buttonPayAndReserve'
                        }
                        onClick={onClickReserve}
                        disabled={handleNextDisabled() || checkDisabled()}
                        data-testid="button_reserve"
                        data-cy="button_reserve"
                      >
                        {`${t('BUTTON_RESERVE')}`}
                      </Button>
                    )}
                  </St.ReservationButtonsWrap>
                ) : (
                  <Button
                    id={getNextButtonId()}
                    className="button"
                    disabled={handleNextDisabled()}
                    onClick={handleNext}
                    data-testid="button_confirm"
                  >
                    {t('BUTTON_NEXT')}
                  </Button>
                )}
              </St.ButtonContainer>
            </div>
            <PoweredBy />
          </div>
        </Drawer>
      )}
      {!isXLarge && ['reservationSummary', 'clientForm'].includes(step) && (
        <Drawer>
          <div className="flex flex-col items-center">
            <St.ReservationButtonsWrap centered={step === 'reservationSummary'}>
              {showPayAndReserveButton() && (
                <Button
                  id="button_mobile_pay_and_book"
                  className="buttonPayAndReserve"
                  disabled={checkDisabled()}
                  onClick={onClickPayAndReserve}
                  data-testid="button_pay_and_reserve"
                  data-cy="button_pay_and_reserve"
                >
                  {`${t('BUTTON_PAY_AND_RESERVE')}`}
                </Button>
              )}
              {showReserveButton() && (
                <Button
                  id="button_mobile_just_book"
                  className={!mustBePaidOnline && showPayAndReserveButton() ? 'btnOutline' : 'buttonPayAndReserve'}
                  onClick={onClickReserve}
                  disabled={checkDisabled()}
                  data-testid="button_reserve"
                  data-cy="button_reserve"
                >
                  {`${t('BUTTON_RESERVE')}`}
                </Button>
              )}
            </St.ReservationButtonsWrap>
            <PoweredBy />
          </div>
        </Drawer>
      )}
      {(bookMutationLoading || redirecting) && (
        <St.BookingLoader>
          <St.BookingLoaderContent>
            <St.BookingLoaderTitle>{t('SCHEDULING_LOADER')}</St.BookingLoaderTitle>
            <Lottie animationData={LoadingAnimation} loop />
          </St.BookingLoaderContent>
        </St.BookingLoader>
      )}
    </StepDialog>
  );
};

export default ReservationModal;
