import { FC } from 'react';
import { Close, MobileArrowLeft } from '@agendapro/emerald-icons';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';

import { Button } from '@agendapro/emerald/v2';
import { Icon, Paragraph, TextHeader } from '@/UI';
import * as St from './TopBar.styles';
import usePageContext from '@/hooks/usePageContext';
import { useLocation } from '@/services/locations';
import { devices } from '@/UI/Styles';

export const TopBar: FC<{
  handleClose?: () => void;
  handleCloseNow?: () => void;
  handlePrevious: () => void;
  previousDisabled: boolean;
  shouldCloseNow?: boolean;
  marketBack?: boolean;
}> = ({ handleClose, handleCloseNow, handlePrevious, previousDisabled, shouldCloseNow, marketBack }) => {
  const router = useRouter();
  const { locationId } = router.query as { locationId: string };
  const { data: currentLocation } = useLocation(locationId);
  const { companyOverview } = usePageContext();
  const { t } = useTranslation();
  const { logo, name } = companyOverview!;
  const isNoMobile = useMediaQuery({
    query: devices['no-mobile'],
  });
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });

  return (
    <>
      {isNoMobile && (
        <St.MainHeader>
          <St.Logo src={logo} alt="logo" width={55} height={55} />
          <div>
            <TextHeader size="sectionHeadline">
              <Paragraph size="subHeadline">{name}</Paragraph>
            </TextHeader>
            <Paragraph weight="light">{currentLocation?.locationName}</Paragraph>
          </div>
          {(handleClose || handleCloseNow) && (
            <St.Close
              onClick={() => {
                if (shouldCloseNow) {
                  handleCloseNow!();
                } else {
                  handleClose!();
                }
              }}
              data-testid="close_icon_button_no_mobile"
            >
              <Close size={25} />
            </St.Close>
          )}
        </St.MainHeader>
      )}
      {!isXLarge && (
        <St.MobileHeader>
          {!previousDisabled && (
            <Button
              className="previousButton"
              variant="link"
              type="button"
              onClick={() => handlePrevious()}
              data-cy="previous_button_mobile"
            >
              <MobileArrowLeft size={16} />
              {t(marketBack ? 'BACK' : 'PREVIOUS')}
            </Button>
          )}
          {(handleClose || handleCloseNow) && !isNoMobile && !marketBack && (
            <St.Close
              onClick={() => {
                if (shouldCloseNow) {
                  handleCloseNow!();
                } else {
                  handleClose!();
                }
              }}
              data-testid="close_icon_button_mobile"
              data-cy="close_icon_button_mobile"
            >
              <Close size={20} />
            </St.Close>
          )}
        </St.MobileHeader>
      )}
    </>
  );
};
